.cover {
    height: 770px;
    width: 600px !important;
}

.coverContainer {
    position: relative;
    color: white;
    
}

.healthCheckcover-h {
    position: absolute;
    font-size: 40px;
    top: 30%;
    left: 40%;
    transform: translate(-50%, -50%)
}

.cover-p {
    position: absolute;
    font-size: 16px;
    top: 45%;
    left: 30%;
    transform: translate(-50%, -50%);
}

.cover-c {
    position: absolute;
    font-size: 16px;
    top: 50%;
    left: 30%;
    transform: translate(-50%, -50%);
}

.cover-d {
    position: absolute;
    font-size: 16px;
    top: 55%;
    left: 30%;
    transform: translate(-50%, -50%);
}

.column {
    margin-top: 5rem;
    margin-bottom: 24rem;
}

.healthCheckh1-column {
    display: block;
    float: left;
    padding-top: 2rem;
    margin-top: 8rem;
    color: rgba(47, 168, 159, 1);
    transform: rotate(-90deg);
    font-weight: 600;
    font-family: Roboto;
    font-size: 30px;
}

.healthCheckTable-column {
    font-size: 18.5px;
    display: inline-block;
    margin-left: -3rem;
}

.td-main {
    width: 280px;
}

.td-sub {
    width: 273px;
}

.td-rec {
    padding-left: 17px;
}

.welcomeText-report {
    /* height: 65px; */
    margin-top: 20px;
    margin-left: 0px;
    font-family: 'Effra', Helvetica, Arial, serif;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    color: rgba(47, 168, 159, 1);
    text-align: left;
    letter-spacing: -0.36px;
    line-height: 36px;
}

.intro {
    width: 600px;
    margin-left: 1rem;
    margin-top: 5rem;
    margin-right: 4rem;
    padding: 1rem 4rem;
    display: block;
}
.intro-ccpa {
    width: 600px;
    margin-left: 2rem;
    
    margin-right: 4rem;
    padding: 1rem 4rem;
    display: block;
}
.intro-furtherinfo {
    width: 600px;
    margin-left: 0rem;
   
    margin-right: 4rem;
    padding: 1rem 4rem;
    display: block;
}
.intro-healthcheck {
    width: 600px;
    margin-left: 1rem;
   
    margin-right: 4rem;
    padding: 1rem 4rem;
    display: block;
}

.intro-h1 {
    font-family: 'Effra', Helvetica, Arial, serif;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    color: rgba(47, 168, 159, 1);
    letter-spacing: -0.36px;
}

.intro-p {
    text-align: justify;
    text-justify: inter-word;
    font-family: 'Effra';
    color: #6e6f70;
    padding: 0rem 6rem 0rem 0rem;
    margin-right: 3rem;
    line-height: 15px;
}

.intro-li {
    margin-right: 35px;
    padding-right: 50px;
    text-align: justify;
    line-height: 15px;
    list-style-type: disc;
}

.gdpr-li {
    margin-bottom: 2rem;
}

.bgColor {
    background-color: #ffffff;
}

@media print {
    body {
        background-color: #ffffff;
        width: 21cm;
        height: 29.7cm;
        margin: 30mm 35mm 30mm 35mm;
        /* change the margins as you want them to be. */
    }
}

.reportData {
    font-size: 12px;
}

.reportData {
    font-size: 12px;
}

.welcomeText-report {
    height: 65px;
    margin-top: 20px;
    margin-left: 0px;
    font-family: 'Effra', Helvetica, Arial, serif;
    font-weight: 400;
    font-style: normal;
    font-size: 36px;
    color: rgba(47, 168, 159, 1);
    text-align: left;
    letter-spacing: -0.36px;
    line-height: 36px;
}

.reportSubHeading {
    font-family: 'Effra', Helvetica, Arial, serif;
    font-weight: 400;
    font-style: normal;
    font-size: 28px;
    color: rgba(47, 168, 159, 1);
}

.roadMapData {
    font-size: 14px;
}

.tr-list {
    width: 400px;
    font-size: 10px;
    font-weight: 200;
    font-family: 'Effra';
    color: #6e6f70;
    line-height: 15px;
    margin-right: 2rem;
    padding-right: 5rem;
    padding-left: 0px;
}

.warning {
    text-align: center;
    line-height: normal;
    font-family: 'Effra';
    color: #6e6f70;
}

.pdfReport {
    font-size: 8px;
    padding: 5px 15px;
}

.reportAddress {
    font-size: 10px;
    font-family: 'Effra', Helvetica, Arial, serif;
    font-weight: 200;
    color: #0090df;
}

.sliderHeading {
    font-family: 'Effra', Helvetica, Arial, serif;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    letter-spacing: -0.36px;
}

.sliderRangeHeading {
    margin-bottom: 14px;
}

.rc-slider {
    width: 400px !important;
}

.sliderMain {
    margin-top: 13px;
}

.rc-slider-rail {
    background: linear-gradient(to right, #EC6F66, #fda737, #2fa89f) !important;
    height: 14px !important;
}

.rc-slider-track {
    background-color: transparent !important;
}

.rc-slider-handle {
    width: 30px !important;
    height: 30px !important;
    margin-top: -7px !important;
    border: solid 7px #6e6f70 !important;
    background-color: transparent !important;
    transform: translateX(0px) !important;
}

.rc-slider-disabled {
    background-color: transparent !important;
}

.non {
    font-size: 10px;
}

.partly {
    font-size: 10px;
    margin-left: 60px;
}

.compliant {
    font-size: 10px;
    margin-left: 100px;
}

.recommendation {
    font-family: 'Effra', Helvetica, Arial, serif;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    color: rgba(47, 168, 159, 1);
}

.recomDiv {
    width: 500px;
    display: block;
}

.recomContent {
    text-align: justify;
    text-justify: inter-word;
    font-family: 'Effra-light';
    color: #6e6f70;
}

.recomContentMain {
    margin-right: 2rem;
    padding-right: 5rem;
}.sr-tr-list-pdf {
    
    text-align: justify;
    text-justify: inter-word;
    width: 400px;
    font-size: 12px;
    font-weight: 200;
    font-family: 'Effra-light';
    color: #6e6f70;
    line-height: 15px;
    
    padding:0 2rem 0.5rem 0;
    
}
.sr-tr-index-pdf {
    
    text-align: justify;
    text-justify: inter-word;
  max-width: 10px;
    font-size: 10px;
    font-weight: 200;
    font-family: 'Effra-light';
    color: #6e6f70;
    line-height: 15px;
    
    padding:0 1rem 0.5rem 0;
    
}
.intro-slider {
    width: 600px;
    margin-left: 1rem;
  
    margin-right: 4rem;
    padding: 1rem 4rem;
    display: block;
}