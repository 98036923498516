html {
  box-sizing: border-box;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
.main .container-fluid {
  padding: 0.5rem 1rem;
}
body {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow-y: scroll;
  margin: 0;
  padding: 0;
  font-family: Effra, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e4e4e4;
}
::-webkit-scrollbar {
  width: 0px;
}
/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: transparent;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

@font-face {
  font-family: Effra;
  src: url("./fonts/effra/Effra_Std_Rg.ttf");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: Effra-Bold;
  src: url("./fonts/effra/Effra_Std_Bd.ttf");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: Effra-Light;
  src: url("./fonts/effra/Effra_Std_Lt.ttf");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: Effra-Medium;
  src: url("./fonts/effra/Effra_Std_Md.ttf");
  font-style: normal;
  font-weight: normal;
}

.cardMain {
  background-color: #e4e4e4 !important;
  height: auto;
  overflow: hidden;
  position: relative;
  border: none !important;
}

.cardGapAnalysis {
  background-color: #e4e4e4 !important;
  height: 1000px;
  color: #e4e4e4;
  position: relative;
  border: none !important;
}

.cardin {
  height: auto;
  border-radius: 10px;
  background-color: #ffffff;
}

.card-body {
  padding: 2em;
}

.color-nav {
  background-color: rgba(27, 23, 25, 1) !important;
  color: #ffffff;
  top: 0px;
  height: 90px;
  margin: 0;
  left: 0px;
}

.logo-nav {
  background-color: rgba(255, 255, 255, 0);
  top: 15px;
  height: 59px;
  width: 252px;
  position: absolute;
  margin: 0;
  left: 44px;
}

.search-nav {
  background-color: rgba(238, 240, 242, 1);
  height: 32px;
  width: 32px;
  margin-top: 12px;
}

.settings-nav {
  background-color: rgba(238, 240, 242, 1);
  height: 32px;
  width: 32px;
  left: 1702px;
  margin-top: 12px;
}

.button-nav {
  background-color: rgba(238, 240, 242, 1);
  color: #1b1719;
  height: 35px;
  width: 98px;
  left: 1778px;
  border-radius: 5px;
  font-weight: 700;
  padding: 0px;
  margin-top: 10px;
}

.sidenav {
  background-color: #2a2c30;
  overflow-y: auto;
  height: auto;
  min-height: 100vh;
  min-width: 17vw;
  padding: 3px;
}
.mobile-sidenav {
  background-color: #2a2c30;
  min-height: 100vh;
  min-width: 200px;
  overflow-y: auto;
  height: auto;
  padding: 10px;
}

.voxLogo {
  margin-top: 700px;
  margin-bottom: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: auto;
}

.welcomeText {
  height: 65px;
  margin-top: 20px;
  margin-left: 18px;
  font-family: "Effra", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 36px;
  color: rgba(47, 168, 159, 1);
  text-align: left;
  letter-spacing: -0.36px;
  line-height: 36px;
}

.welcomeText-gapAnalysis {
  height: 65px;
  margin-top: 20px;
  margin-left: -8px;
  font-family: "Effra", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 36px;
  color: rgba(47, 168, 159, 1);
  text-align: left;
  letter-spacing: -0.36px;
  line-height: 36px;
}

.welcomeText-services {
  height: 65px;
  margin-top: 20px;
  margin-left: 5px;
  font-family: "Effra", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 36px;
  color: rgba(47, 168, 159, 1);
  text-align: left;
  letter-spacing: -0.36px;
  line-height: 36px;
}

.welcomeText-asset,
.welcomeText-process {
  height: 65px;
  margin-top: 20px;
  margin-left: 2px;
  font-family: "Effra", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 36px;
  color: rgba(47, 168, 159, 1);
  text-align: left;
  letter-spacing: -0.36px;
  line-height: 36px;
}

.welcomeText-report {
  height: 65px;
  margin-top: 20px;
  margin-left: 0px;
  font-family: "Effra", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 36px;
  color: rgba(47, 168, 159, 1);
  text-align: left;
  letter-spacing: -0.36px;
  line-height: 36px;
}

.welcomeText-dash {
  height: 65px;
  margin-top: 20px;
  margin-left: -15px;
  font-family: "Effra", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 36px;
  color: rgba(47, 168, 159, 1);
  text-align: left;
  letter-spacing: -0.36px;
  line-height: 36px;
}

.welcomeText-users {
  height: 65px;
  margin-top: 20px;
  margin-left: 7px;
  font-family: "Effra", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 36px;
  color: rgba(47, 168, 159, 1);
  text-align: left;
  letter-spacing: -0.36px;
  line-height: 36px;
}

.userText {
  height: 45px;
  left: 50px;
  font-family: "Effra", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 25px;
  color: rgba(47, 168, 159, 1);
  text-align: left;
  letter-spacing: -0.36px;
  line-height: 36px;
}

.userFirstName-Input:focus {
  border: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 0 4px #666 inset;
  -moz-box-shadow: 0 0 4px #666 inset;
  box-shadow: 0 0 4px #666 inset;
}

.companyName-Input:focus {
  border: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 0 4px #666 inset;
  -moz-box-shadow: 0 0 4px #666 inset;
  box-shadow: 0 0 4px #666 inset;
}
.companyName:focus {
  border: block;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 0 4px #666 inset;
  -moz-box-shadow: 0 0 4px #666 inset;
  box-shadow: 0 0 4px #666 inset;
}

.inviteInput:focus {
  border: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 0 4px #666 inset;
  -moz-box-shadow: 0 0 4px #666 inset;
  box-shadow: 0 0 4px #666 inset;
}

.form-asset:focus {
  border: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 0 4px #666 inset;
  -moz-box-shadow: 0 0 4px #666 inset;
  box-shadow: 0 0 4px #666 inset;
}

/* .org-input {
    z-index: 9999 !important;
    border: none;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: 0 0 4px #666 inset;
    -moz-box-shadow: 0 0 4px #666 inset;
    box-shadow: 0 0 4px #666 inset;
} */

.gdprCompText {
  height: 40px;
  margin-top: 20px;
  left: 50px;
  font-family: "Effra", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 36px;
  color: rgba(47, 168, 159, 1);
  text-align: left;
  letter-spacing: -0.36px;
  line-height: 36px;
}

.gdprCompTestCat {
  height: 40px;
  margin-top: 20px;
  left: 50px;
  font-family: "Effra", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  color: rgba(47, 168, 159, 1);
  text-align: left;
  letter-spacing: -0.36px;
  line-height: 30px;
  margin-left: -16px;
}

.gdprCompTestSubCat {
  left: 50px;
  font-family: "Effra", Helvetica, Arial, serif;
  font-style: normal;
  font-size: 20px;
  color: rgba(47, 168, 159, 1);
  text-align: left;
  letter-spacing: -0.36px;
  line-height: 20px;
  margin-bottom: 8px;
}

.gdprCompTestQues {
  margin-bottom: 40px;
  margin-right: 5px;
  font-family: "Effra", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  color: rgba(47, 168, 159, 1);
  text-align: left;
  letter-spacing: -0.36px;
  line-height: 22px;
}

.questionSummary {
  margin-bottom: 40px;
  margin-right: 5px;
  font-family: "Effra", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  color: "black";
  text-align: left;
  letter-spacing: -0.36px;
  line-height: 22px;
}

.gdprCompTestQues img {
  position: absolute;
  right: 0px;
}

.gdprQuestion {
  margin-top: 20px;
  margin-bottom: 20px;
}

.modalBodyGdpr {
  height: 22em !important;
}

.questionBorder {
  border: 2px solid #e7e7e7;
  border-radius: 5px;
  padding: 30px;
  /* margin-top: -18px; */
}

.questionBorder-info {
  border: 2px solid #e7e7e7;
  border-radius: 5px;
  padding: 30px;
  margin-top: 24px;

  height: 650px;
  overflow-y: scroll;
}

.questionBorder-short {
  border: 2px solid #e7e7e7;
  border-radius: 5px;
  padding: 30px;
  margin-top: 24px;
  height: 620px;
  overflow-y: scroll;
}

.infoBorder {
  border: 2px solid #e7e7e7;
  padding-right: 30px;
  padding-left: 30px;
  padding-bottom: 10px;
  padding-top: 10px;
  height: 700px;
  margin-right: 10px;
  border-radius: 5px;
}

.gdprCompImage {
  position: absolute;
  height: 155px;
  top: 90px;
}

.outerCircle-container {
  position: relative;
  width: 450px;
  height: 450px;
  padding: 50px;
  margin: 50px auto 0;
}

.circle-container {
  position: relative;
  width: 95%;
  height: 100%;
}

.outerCircle-container a {
  display: block;
  position: absolute;
  width: 7em;
  height: 4em;
  margin: -3em;
}

.outerCircle-container img {
  display: block;
  width: 100%;
  overflow: hidden;
}

.circle-container div {
  display: block;
  margin: auto;
}

.circle-container img {
  display: block;
  width: 100%;
  overflow: hidden;
}

.segmentIcon {
  height: 50px;
  position: relative;
}

.tasksCount {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: rotate(60deg) translate(0%, 0%);
  background-color: black;
  border-radius: 50%;
  color: white;
  width: 25%;
  text-align: center;
  height: 33%;
}

.deg0 {
  transform: translate(18em);
}

.deg0Segment {
  transform: translate(12em);
}

.deg30 {
  transform: rotate(30deg) translate(18em) rotate(-30deg);
}

.deg30Segment {
  transform: rotate(30deg) translate(12em) rotate(-30deg);
}

.deg60 {
  transform: rotate(60deg) translate(18em) rotate(-60deg);
}

.deg60Segment {
  transform: rotate(60deg) translate(12em) rotate(-60deg);
}

.deg90 {
  transform: rotate(90deg) translate(18em) rotate(-90deg);
}

.deg90Segment {
  transform: rotate(90deg) translate(12em) rotate(-90deg);
}

.deg120 {
  transform: rotate(120deg) translate(18em) rotate(-120deg);
}

.deg120Segment {
  transform: rotate(120deg) translate(12em) rotate(-120deg);
}

.deg150 {
  transform: rotate(150deg) translate(18em) rotate(-150deg);
}

.deg150Segment {
  transform: rotate(150deg) translate(12em) rotate(-150deg);
}

.deg180 {
  transform: translate(-18em);
}

.deg180Segment {
  transform: translate(-12em);
}

.deg210 {
  transform: rotate(210deg) translate(20em) rotate(-210deg);
}

.deg210Segment {
  transform: rotate(210deg) translate(12em) rotate(-210deg);
}

.deg240 {
  transform: rotate(240deg) translate(20em) rotate(-240deg);
}

.deg240Segment {
  transform: rotate(240deg) translate(12em) rotate(-240deg);
}

.deg270 {
  transform: rotate(270deg) translate(21em) rotate(-270deg);
}

.deg270Segment {
  transform: rotate(270deg) translate(12em) rotate(-270deg);
}

.deg300 {
  transform: rotate(300deg) translate(20em) rotate(-300deg);
}

.deg300Segment {
  transform: rotate(300deg) translate(12em) rotate(-300deg);
}

.deg330 {
  transform: rotate(330deg) translate(18em) rotate(-330deg);
}

.deg330Segment {
  transform: rotate(330deg) translate(12em) rotate(-330deg);
}

.swap-on-hover {
  position: relative;
}

.swap-on-hover .swap-on-hover__front-image {
  z-index: 9999;
  transition: opacity 0.5s linear;
}

.swap-on-hover .swap-on-hover__back-image {
  opacity: 0;
}

.swap-on-hover:hover > .swap-on-hover__front-image {
  opacity: 0;
}

.swap-on-hover:hover > .swap-on-hover__back-image {
  opacity: 1;
}

.currentUserListPanel {
  width: 1020px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 5px;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  padding: 25px;
}

.userList {
  margin-left: unset;
  margin-right: unset;
}

.currentUserText {
  height: 45px;
  left: 50px;
  font-family: "Effra", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 25px;
  color: rgba(47, 168, 159, 1);
  text-align: left;
  letter-spacing: -0.36px;
  line-height: 36px;
}

.tableColumnHeaderText {
  height: 18px;
  font-family: "Effra", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  color: rgba(47, 168, 159, 1);
  text-align: left;
  letter-spacing: -0.36px;
  line-height: 18px;
}

.noticeCardPanel {
  flex-wrap: nowrap;
  margin-bottom: 20px;
}

.packagesCardPanel {
  /* flex-wrap: nowrap; */
  margin-bottom: 20px;
  margin-top: 20px;
  overflow-y: scroll;
  height: 670px;
}

.calendarPanel {
  flex-wrap: nowrap;
  margin-top: 20px;
  margin-right: -30px;
  margin-left: -15px;
  transform: rotate(0deg);
  opacity: 1;
}

.react-calendar {
  width: auto !important;
  height: 400px;
  font-size: x-small !important;
  line-height: 4em !important;
}

.react-calendar__month-view__weekdays {
  font-size: 1em !important;
}

.react-calendar__navigation__label {
  font-size: 20px;
}

.react-calendar__tile--active {
  background: #dc034e !important;
  border-radius: 50%;
}

.noticeCard {
  top: 0px;
  margin-top: 10px;
  box-sizing: border-box;
  width: 368px;
  margin: 0px 5px 0px 0px;
  left: 0px;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(231, 231, 231, 1);
  background-color: #e7e7e7;
}

.widgetCard {
  top: 10px;
  /* box-sizing: border-box; */
  width: 368px;
  box-sizing: border-box;
  height: 300px;
  margin: 5px 5px 0px 0px;
  left: 0px;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(231, 231, 231, 1);
  background-color: #e7e7e7;
}

.noPaddingColumn {
  position: relative;
  width: 100%;
  padding-right: 0px;
  padding-left: 0px;
}

.userCard {
  top: 10px;
  margin-top: 10px;
  box-sizing: border-box;
  min-width: 250px;
  left: 0px;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(231, 231, 231, 1);
  background-color: #e7e7e7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.squareWidgetCard {
  top: 0px;
  box-sizing: border-box;
  width: 300px;
  margin: 10px 5px 0px 0px;
  left: 0px;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(231, 231, 231, 1);
  background-color: #e7e7e7;
}

.dashboardCard {
  top: 0px;
  box-sizing: border-box;
  /* width: 300px; */
  margin: 10px 5px 0px 0px;
  left: 0px;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(231, 231, 231, 1);
  background-color: #e7e7e7;
}

.calendarCard {
  border-style: solid;
  border-radius: 5px;
  border-width: 1px;
  border-color: rgba(231, 231, 231, 1);
  margin-bottom: 20px;
  text-align: center;
}

.hoverCard {
  cursor: pointer;
}

.hoverCard:hover {
  background-color: #c1c1c1;
}

.noticeCard p {
  /* font-size: 15px; */
}

.panel_bell {
  top: 0px;
  height: 105px;
  width: 200px;
  margin-bottom: 22px;
  left: 0px;
  border-radius: 5px;
  padding: 0px;
  cursor: pointer;
  justify-content: center;
}

.alertPanel {
  top: 0px;
  height: 105px;
  width: 200px;
  margin-bottom: 22px;
  margin: auto;
  left: 0px;
  border-radius: 5px;
  padding: 0px;
  cursor: pointer;
  justify-content: center;
  margin-top: 10px;
}

.vendorAlertPanel {
  top: 0px;
  height: 105px;
  /* width: 200px; */
  margin-bottom: 22px;
  margin: auto;
  left: 0px;
  border-radius: 5px;
  padding: 0px;
  cursor: pointer;
  justify-content: center;
  margin-top: 10px;
}
.contactPanel {
  top: 0px;
  height: 105px;
  /* width: 200px; */
  margin-bottom: 22px;
  margin: auto;
  left: 0px;
  border-radius: 5px;
  padding: 0px;
  cursor: pointer;
  justify-content: center;
  margin-top: 10px;
}

.contactPanel {
  /* padding-top: 20px; */
  text-align: center;
  color: #ffffff;
}

.contactHeading {
  font-family: "Effra", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  color: white;
  font-size: 15px;
  /* letter-spacing: -0.36px; */
  line-height: 30px;
  border-radius: 10px;
}

.dashboardAlertPanel {
}

.panel_bell_red {
  background-color: #e33f34;
}

.panel_bell_skyblue {
  background-color: #1ea3cf;
}

.panel_bell_darkblue {
  background-color: #3c3484;
}

.support_video_panel {
  height: 360px;
  background-color: #e7e7e7;
  border-radius: 5px;
}

.userSupport_video_panel {
  height: 360px;
  background-color: #e7e7e7;
  border-radius: 5px;
  height: 360px;
  background-color: #e7e7e7;
  border-radius: 5px;
  margin-top: 10px;
  margin-inline: 10px;
}

.support_text {
  margin-top: 10px;
  margin-left: 18px;
  font-size: 20px;
}

.scrollable {
  overflow-y: auto;
  max-height: 300px;
}

.videoCard {
  display: block;
  width: 100%;
  height: auto;
}

.videoCard.text {
  margin-top: 5px;
  font-size: 10px;
  /* padding: 10px; */
  line-height: 18px;
}

.VideoText {
  font-size: 13px;
  font-weight: 200;

  margin-left: 20px;
}

.UserVideoText {
  font-size: 13px;
  font-weight: 200;

  /* margin-left: 20px; */
}
.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #1ea3cf;
}

.videoPlaceHolder {
  left: 1px;
}

.scrollable::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #c1c1c1;
}

.bellIconPanel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  text-align: center;
  color: #ffffff;
}

.bellIconPanel p {
  font-size: 12px;
}

.daisyIconPanel {
  padding: 20px;
  text-align: center;
  color: #2a2c30;
}

.dashboardIconPanel {
  padding: 5px;
  text-align: center;
  color: #2a2c30;
}

.daisyIconPanel h4 {
  font-size: 17px;
}

.dpDaisy {
  background: #ffffff;
  border-radius: 50%;
}

.displayPic {
  height: 200px;
  width: 400px;
  margin-left: 0px !important;
  margin-top: -25px !important;
}

.companyName {
  margin-top: 5px;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 0px;
}

.cardWidgetSubtitle {
  margin-top: 5px;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 0px;
}

.cardWidgetDescription {
  margin-top: 5px;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0px;
}

.modalInputField {
  margin-top: 5px;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 0px;
}

.fullPageInputField {
  margin-top: 5px;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 0px;
  width: 100%;
}

.account {
  margin-top: 5px;
  font-weight: 900;
  font-size: 20px;
  margin-bottom: 0px;
}

.scrollListName {
  margin-top: 5px;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0px;
}

.fileUpload {
  display: none;
}

.image-upload img {
  margin-left: 120px;
  cursor: pointer;
}

.image-upload .orgImage {
  margin-left: 180px;
  cursor: pointer;
}

.label-upload {
  margin-bottom: -5px;
}

.companyEmail {
  font-size: 10px;
  font-weight: 300;
  margin-bottom: 0px;
}

.videoText {
  font-size: 15px;
}

.orgForm {
  margin-left: 20px;
  width: 1020px;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  padding: 25px;
}

.inviteForm {
  margin-left: 20px;
  width: 800px;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  padding: 25px;
}

.fullPageForm {
  margin-left: 20px;
  width: 100%;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  padding: 25px;
  overflow-y: scroll;
}

.createQuestionnaireScroller {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 800px;
  width: 100%;
  padding: 5px;
  margin-top: 10px;
  /* border: 1px solid #e7e7e7; */
  margin-bottom: 5px;
  flex: 1;
}

.saveBtn {
  margin-right: 10px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #2fa89f;
  color: #ffffff;
}

.searchBtn {
  background-color: #2fa89f;
  color: #ffffff;
}

.saveResponse {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 45px;
  padding-right: 45px;
  background-color: #2fa89f;
  color: #ffffff;
  float: right;
}

.removePic {
  margin-top: 10px;
}

.orgPicUpload {
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  height: 200px;
  width: 400px;
}

.cancelBtn {
  padding-left: 45px;
  padding-right: 45px;
  background-color: #d6eeec;
  border-color: #d6eeec;
  color: #2fa89f;
}

.profileName {
  font-size: 20px;
  color: #fff;
}

.contentMain {
  position: relative;
}

.rightSideBar {
  width: 285px;
  height: 824px;
  position: absolute;
  top: 0;
  right: -15px;
  padding: 0;
  background-color: #f7f7f7;
  border-radius: 5px;
}

.progressWidget {
  /* width: 285px; */
  height: 700px;
  /* position: absolute; */
  top: 0;
  padding: 10px;
  margin-top: 10px;
  background-color: #e7e7e7;
  border-radius: 5px;
  overflow: hidden;
}
.progressWidgetNoCircle {
  width: 285px;
  /* height: 500px; */
  /* position: absolute; */
  top: 0;
  right: -15px;
  padding: 10px;
  margin-top: 10px;
  background-color: #e7e7e7;
  border-radius: 5px;
  overflow: auto;
}

.gapRightSideBar {
  width: 380px;
  height: 824px;
  position: absolute;
  margin-right: -15px;
  margin-top: 0px;

  top: 0;
  right: 0;
  padding: 1px;
  background-color: #f7f7f7;
}

.gapCircleContainer {
  position: relative;

  width: 70px;
  height: 100%;
  margin-left: -70px;
  margin-top: -90px;
}

.gapForm {
  margin-top: -20px;
  width: 980px;

  border: 1px solid #e7e7e7;
  padding: 15px;
}

.gapForm-gapAnalysis {
  margin-left: 8px;
  margin-top: -20px;
  width: 980px;
  height: 700px !important;
  overflow-y: scroll;
  border: 1px solid #e7e7e7;
  padding: 15px;
}

.gapForm-info {
  margin-left: 20px;
  margin-top: 25px;
  width: 980px;

  height: 650px;
  overflow-y: scroll;

  border: 1px solid #e7e7e7;
  padding: 15px;
}

.gapForm-short {
  margin-left: 20px;
  margin-top: 25px;
  width: 980px;

  height: 620px;
  overflow-y: scroll;

  border: 1px solid #e7e7e7;
  padding: 15px;
}

.gapForm-infoRow {
  height: 700px !important;
}

.gapForm-catogories {
  text-align: start;
}

.gapForm-img {
  margin-left: 60px;
  margin-top: 180px;
  width: 350px;
  filter: brightness(95%);
  border: 0px;
  padding: 15px;
}

.RightScrollStyle {
  max-height: 450px;
  overflow-y: scroll;
}

.ProgressWidgetScrollStyle {
  max-height: 450px;
  flex: 1;
  overflow-y: scroll;
  /* margin-right: 5px; */
  margin-top: 20px;
  margin-bottom: 20px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.ProgressWidgetScrollStyle::-webkit-scrollbar {
  display: none;
}

.NoCircleProgressWidgetScrollStyle {
  max-height: 100%;
  overflow-y: auto;
  margin-right: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.NoCircleProgressWidgetScrollStyle::-webkit-scrollbar {
  display: none;
}

.NoCircleProgressWidgetScrollStyle::-webkit-scrollbar {
  display: none;
}

.GapRightScrollStyle {
  max-height: 200px;
  overflow-y: scroll;
}

.QuesScrollStyle {
  max-height: 650px;
  overflow-y: scroll;
}

.catDesc {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 15px;

  overflow-x: hidden;
  overflow-y: scroll;

  max-height: 550px;
}

.catDesc::-webkit-scrollbar {
  width: 0px;
}

.rightSideBarHeading {
  text-align: center;
  margin-top: 10px;
}

.rightSideBarSubtitle {
  text-align: center;
  font-size: 14px;
}

.choiceInput {
  max-width: 140px;
  margin-left: 4px;
}

.choiceAnswers {
  margin-left: 0px !important;
}

.rightBarMargin {
  margin-bottom: 10px;
}

.CircularProgressbar {
  /*
     * This fixes an issue where the CircularProgressbar svg has
     * 0 width inside a "display: flex" container, and thus not visible.
     */
  width: 100%;
  /*
     * This fixes a centering issue with CircularProgressbarWithChildren:
     * https://github.com/kevinsqi/react-circular-progressbar/issues/94
     */
  vertical-align: middle;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #e33f34;
  stroke-linecap: round;
  -webkit-transition: stroke-dashoffset 0.5s ease 0s;
  transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #2fa89f;
  /* Used when trail is not full diameter, i.e. when props.circleRatio is set */
  stroke-linecap: round;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #2a2c30;
  font-size: 20px;
  dominant-baseline: middle;
  text-anchor: middle;
}

.CircularProgressbar .CircularProgressbar-background {
  fill: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted
  .CircularProgressbar-background {
  fill: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
  fill: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
  stroke: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
  stroke: transparent;
}

.react-sweet-progress-line {
  background-color: #e33f34 !important;
}

.red > .react-sweet-progress-line {
  background-color: #e33f34 !important;
}

.amber > .react-sweet-progress-line {
  background-color: #fda737 !important;
}

.green > .react-sweet-progress-line {
  background-color: #2fa89f !important;
}

.cardData {
  padding: 5px;
}

.input-group {
  background-color: #ffffff !important;
  border: transparent !important;
}

input[type="radio"] {
  width: 15px;
  height: 15px;
  margin-top: 8px;
}

input[type="radio"]:after {
  width: 15px;
  height: 15px;
  border-radius: 19px;
  top: -1.7px;
  left: 0px;
  position: relative;
  background-color: #ffffff;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 1px solid #c1c1c1;
}

input[type="radio"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 19px;
  top: -2.4px;
  left: 0px;
  position: relative;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 0px solid white;
  color: red;
  background-color: rgba(47, 168, 159, 1) !important;
}

.gapAnalysis-radio {
  margin-bottom: 0px;
}

.gapAnalysis-radio[type="radio"] {
  width: 15px;
  height: 15px;
  margin-top: 6px;
}

.gapAnalysis-radio[type="radio"]:after {
  width: 15px;
  height: 15px;
  border-radius: 19px;
  top: -4px;
  left: 0px;
  position: relative;
  background-color: #ffffff;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 1px solid #c1c1c1;
}

.gapAnalysis-radio[type="radio"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 19px;
  top: -4px;
  left: 0px;
  position: relative;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 0px solid white;
  color: red !important;
  background-color: rgba(47, 168, 159, 1) !important;
}

.newQuestion-radio {
  margin-bottom: 0px;
}

.newQuestion-radio[type="radio"] {
  width: 15px;
  height: 15px;
  margin-top: 6px;
}

.newQuestion-radio[type="radio"]:after {
  width: 15px;
  height: 15px;
  border-radius: 19px;
  top: -4px;
  left: 0px;
  position: relative;
  background-color: #ffffff;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 1px solid #c1c1c1;
  opacity: 0%;
}

.newQuestion-radio[type="radio"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 19px;
  top: -4px;
  left: 0px;
  position: relative;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 0px solid white;
  color: red !important;
  /* background-color: rgba(47, 168, 159, 1) !important; */
  background-color: black !important;
  opacity: 0%;
}

.subCatIcon {
  height: 80px;
}

.formTextInfo {
  font-size: 12px;
  font-weight: 200;
}

.formLabel {
  font-size: 15px;
}

.form-control.is-valid {
  background-image: none;
}

.form-control {
  resize: none;
}

.tasksCompletion {
  background-color: #d6eeec;
  color: #2a2c30;
  margin-left: 5px;
  padding: 15px;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  margin-bottom: 20px;
}

.taskData {
  padding: 10px;
  margin-top: 10px;
  max-height: 320px;
}

.scrollTasks {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 310px;
}

ul.scrollTasks {
  list-style: none;
  font-size: 20px;
  margin-bottom: 10px;
  margin-top: 20px;
}

li.taskList {
  padding-left: 0em;
  text-indent: -0.7em;
  font-size: 14px;
  border-bottom-style: ridge;
  border-bottom-color: black;
  border-bottom-width: 1px;
  font-family: "Roboto", sans-serif;
  color: #666;
}
li.taskList:hover {
  color: rgb(38, 38, 41);
  cursor: pointer;
}
ul.scrollTasks li::before {
  content: "\2022";
  color: #e33f34;
  font-size: 30px;
  padding-right: 5px;
}

.tasksHeading {
  margin-top: 10px;
}

.incidentRegister {
  background-color: #dc034e;
  color: #ffffff;
  margin-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  margin-bottom: 20px;
}

.incidentData {
  padding: 10px;
  margin-top: 10px;
  max-height: 260px;
}

.incidentHeading {
  margin-top: 10px;
}

.incidentScroll {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 300px;
  padding: 5px;
}

.rdrMonth {
  width: 37.667em !important;
}

.rdrCalendarWrapper {
  height: 330px !important;
  font-size: 14px !important;
}

span.rdrStartEdge.rdrEndEdge {
  top: 7px;
  width: 40%;
  left: 21px !important;
  height: 66%;
  border-radius: 50%;
}

.batch-count {
  position: absolute;
  right: 25px;
}

.popupbox-content {
  padding: 0px !important;
}

.usersList {
  font-size: 15px;
}

.userPic {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: red;
  color: white;
  margin-right: 10px;
  position: absolute;
  transform: translate(0px, -3px);
}

.progressIndicatorDot {
  border-radius: 100%;
  width: 30px;
  height: 30px;
  background: red;
  color: white;
  margin-right: 10px;
  position: absolute;
  transform: translate(0px, -3px);
  line-height: 20px;
  display: "inline-block";
}

.userShortName {
  font-size: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.userName {
  margin-left: 46px;
}

.loggedInTime {
  margin-left: 180px;
  text-align: right;
}

.loggedInTimeText {
  font-size: 15px;
  color: blue;
  right: -40px;
  position: relative;
}

.dots {
  float: right;
}

.tippy-box[data-theme~="blue"] {
  background: #0090df;
  color: #ffffff;
}

.tippy-box[data-theme~="blue"][data-placement^="left"] > .tippy-arrow::before {
  border-left-color: #0090df;
}

.tippy-box[data-theme~="blue"][data-placement^="top"] > .tippy-arrow::before {
  border-top-color: #0090df;
}

.userScroller {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 245px;
  padding: 5px;
}

.gapAnalysisScroller {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 740px;
  padding: 5px;
}

.feedbackScroller {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 750px;
  padding: 5px;
}

.entityListScroller {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 580px;
  width: 100%;
  padding: 5px;
  margin-top: 10px;
  /* border: 1px solid #e7e7e7; */
  margin-bottom: 5px;
  flex: 1;
}

.processFlowGrid {
  height: 650px;
  width: 1000px;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid #e7e7e7;
  padding: 25px;
}

.entityListGrid {
  height: 1000px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  /* border: 1px solid #e7e7e7; */
  padding: 25px;
}

.processFlowGrid-analysis {
  margin-top: 20px;
  margin-bottom: 20px;
  height: 1000px;
  border: 1px solid #e7e7e7;
  padding: 25px;
}

.processForm {
  float: right;
}

.searchBarProcess {
  padding-left: 29px;
  margin-right: 5px;
  width: 200px;
  /* height: 35px; */
}

.searchBarProcess:focus {
  border: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 0 4px #666 inset;
  -moz-box-shadow: 0 0 4px #666 inset;
  box-shadow: 0 0 4px #666 inset;
}

.search-icon {
  position: absolute;
  margin-top: 0px;
  width: 25px !important;
  height: 33px !important;

  /* filter: brightness(40%); */
  opacity: 0.4;
}

.widget-search-icon:hover {
  cursor: pointer;
}

.processFlowButton {
  /* width: 130px; */
  /* height: 35px; */
  right: 2px;
  margin-left: 30px;
  margin-right: -22px;
  background-color: #2fa89f;
  color: #ffffff;
}
.edit-processFlowButton {
  /* width: 130px; */
  /* height: 35px; */
  /* justify-content: left; */
  display: inline-flex;
  float: left;
  right: 2px;
  /* margin-left: 30px; */
  /* margin-right: -22px; */
  background-color: #2fa89f;
  color: #ffffff;
}
.processFlowButton-save {
  /* width: 130px; */
  /* height: 35px; */
  right: 2px;
  margin-left: 92px;
  margin-right: 0px;
  background-color: #2fa89f;
  color: #ffffff;
}

.processFlowButton-new {
  /* width: 130px; */
  /* height: 35px; */
  right: 2px;
  width: 123px;
  margin-left: 20px;
  margin-right: 0px;
  background-color: #2fa89f;
  color: #ffffff;
}

.btnIcons {
  margin-right: 5px;
}

.processForm {
  margin-top: 30px;
}

.processText {
  font-size: 22px;
  font-weight: 400;
  margin-left: 12px;
  color: #2fa89f;
}

.processTableContainer {
  overflow-y: scroll;
  height: 500px;
}

.processTableHeading {
  font-size: 17px;
  margin-left: -3px;
  font-weight: 400;
}

.processList {
  font-size: 15px;
  line-height: 4em;
}

.processLabel {
  font-size: 12px;
}

.processTableText {
  font-size: 12px;
}

#processFlowSelect {
  background-color: #d6eeec;
  color: #2a2c30;
}

.vl {
  border-left: 2px solid #e7e7e7;
  height: 500px;
  position: absolute;
  left: 30%;
  margin-left: -3px;
  top: 0;
  margin-top: 50px;
}

.processSidebar {
  font-size: 15px;
  line-height: 4em;
  cursor: pointer;
}

.processQues {
  font-size: 1.1rem !important;
  color: #2fa89f;
}

.crossIcon {
  margin-top: 35px;
}

.infoSelect {
  color: #2fa89f !important;
}

.infoInput {
  background-color: #d6eeec !important;
  color: #2fa89f !important;
}

.MuiTablePagination-actions {
  margin-left: 200px !important;
}

.MuiSelect-select.MuiSelect-select {
  background-color: #ffffff;
}

/* .MuiListItem-root.Mui-selected {
    background-color: #ffffff;
} */

.processInfoForm {
  margin-top: 30px;
  margin-bottom: 20px;
}

.gapChoice {
  margin-left: 4px !important;
}

.policyProcedureList {
  cursor: pointer;
  color: #2fa89f;
}

.reportsList {
  margin-top: 20px;
  margin-bottom: 20px;
}

.policyData {
  font-size: 15px;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 700px;
  padding: 5px;
}

.activeTab {
  padding: 5px;
  color: #2fa89f;
}

.reportContainer {
  margin-top: 20px;
  position: relative;
  width: 50%;
  text-align: center;
  color: white;
}

.bottom-right {
  position: absolute;
  text-align: start;
  bottom: 8px;
  right: 10px;
  margin-right: 20px;
}

.reportCoverData {
  position: absolute;
  font-size: 50px;
  top: 45%;
  left: 40%;
  transform: translate(-50%, -50%);
}

.bottom-right > h6 {
  padding-left: 120px;
}

.top-right {
  position: absolute;
  top: 8px;
  right: 26px;
}

.bgReport {
  width: 780px;
  height: 740px;
  margin-left: -27px;
  /* padding-right: 1rem; */
}

.image-wrapper {
  width: 100%;
}

.reportNav {
  font-size: 14.5px;
  cursor: pointer;
  background: #e4e4e4;
  margin-right: -15px;
}

.reportScroll {
  max-height: 700px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.reportContent {
  font-size: 12px;
  padding: 5px 15px;
  margin-left: 2rem;
}

.serviceRoadMapList {
  cursor: pointer;
}

.reportContentHeading {
  color: #2fa89f;
  font-size: 17px;
  margin-left: 2rem;
}

.companyReportLogoContainer {
  display: flex;
}

.companyReportLogo {
  height: 100px;
  margin: auto;
  filter: brightness(95%);
  opacity: 1;
}

.reportInfoHeight {
  height: 600px;
  margin-left: -3rem;
}

.ccpa-reportInfoHeight {
  height: 600px;
  margin-top: 1rem;
}

.downloadReport {
  background-color: transparent !important;
  border-color: transparent !important;
  position: absolute;
  bottom: 5px;
  cursor: pointer;
  margin-left: 550px;
  font-size: 18px;
  color: #0090df;
}

.downloadReport:hover {
  background-color: transparent !important;
  border-color: transparent !important;
}

.viewReport {
  position: absolute;
  bottom: 5px;
  cursor: pointer;
  font-size: 18px;
  color: #0090df;
}

.downloadIcon {
  margin-top: 1px;
  width: 27px;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.gapScroller {
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: 1px !important;
}

.gapScroller::-webkit-scrollbar {
  background: #fff;
  width: 0px;
}

.backLink {
  float: right;
  margin-top: -35px;
  height: 20px;
  cursor: pointer;
}

.hide {
  display: none !important;
  width: 95px !important;
  height: 75px !important;
}

.show {
  display: block !important;
}

.show-div {
  /* padding-right: -30px; */
  width: 6.2rem;
  padding: auto;
  margin-left: -15px !important;
}

.MuiListItemText-inset {
  padding-left: 36px !important;
}

.MenuBar-draw-4 {
  /* border-radius: 10px; */
  height: 100% !important;
}

.MenuBar-draw-8 {
  /* border-radius: 10px; */
  height: 100% !important;
}

.MuiListItem-root {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.MuiTypography-body1 {
  font-size: 12px !important;
}

.MuiTypography-body2 {
  margin-left: -12px !important;
}

.jss4 {
  /* border-radius: 10px; */
  height: 100% !important;
}

.jss8 {
  /* border-radius: 10px; */
  height: 100% !important;
}

.rdrCalendarWrapper.rdrDateRangeWrapper {
  margin-left: auto;
  margin-right: auto;
}

img.greenArrow {
  margin-top: -5px;
  margin-left: 5px;
  width: 15px;
}

.rdrNextPrevButton {
  border-radius: 50% !important;
  /* display:none !important; */
}

.widgetProgressBarTitle {
  cursor: pointer;
}

.MuiCollapse-wrapperInner
  .MuiTypography-root.MuiListItemText-primary.MuiTypography-body1.MuiTypography-displayBlock:hover {
  color: rgba(47, 168, 159, 1);
}

/* This is the class used for displaying the green bar next to a navigation item */
/* .MuiCollapse-wrapperInner .MuiListItemText-root.MuiListItemText-inset {
    border-left: 5px solid rgba(47, 168, 159, 1);
} */

.activeMenuItem {
  border-left: 5px solid rgba(47, 168, 159, 1);
  margin-right: -5px;
}

.tippy-content {
  width: auto;
}

button.menu-shadow.btn.btn-secondary {
  border-radius: 10px;
  /* width: 19rem; */
  /* height: 2rem; */
}

.tableHeight {
  max-height: 700px;
}

.tr-dot {
  max-width: 20px;
}
/* toast */
.Toastify__toast--success {
  width: 28rem !important;
  background-color: #2fa89f;
  height: 5rem;
  margin-top: 15rem;
}

.ccpa-tr-index {
  text-align: justify;
  text-justify: inter-word;
  max-width: 10px;
  font-size: 10px;
  font-weight: 200;
  font-family: "Effra-light";
  color: #6e6f70;
  line-height: 15px;

  padding: 0 1rem 1rem 0;
}

.ccpa-tr-list-pdf {
  text-align: justify;
  text-justify: inter-word;
  width: 400px;
  font-size: 12px;
  font-weight: 200;
  font-family: "Effra-light";
  color: #6e6f70;
  line-height: 15px;

  padding: 0 2rem 0.5rem 0;
}
.ccpa-tr-index-pdf {
  text-align: justify;
  text-justify: inter-word;
  max-width: 10px;
  font-size: 10px;
  font-weight: 200;
  font-family: "Effra-light";
  color: #6e6f70;
  line-height: 15px;

  padding: 0 1rem 0.5rem 0;
}

.btnSaveResponse {
  background-color: transparent;
  color: #2fa89f;
}

.btnSaveResponse-serv {
  background-color: transparent;
  color: #2fa89f;
}

.btnSaveResponse-gap {
  background-color: transparent;
  color: #2fa89f;
}

.btnSaveResponse:hover .btnSaveResponse-gap:hover,
.btnSaveResponse-serv:hover {
  background-color: transparent;
  color: #fda737;
}

.pdfScroller {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 800px;
}

.cover-health {
  margin: 1rem auto;
  color: #404e67;
  font-family: Montserrat, Georgia, "Times New Roman", Times, serif;
  font-size: 24.36px;
  line-height: 29.232px;
  /* 400 */
}

.healthConclusion-h {
  margin: 2rem 0rem;
  color: #404e67;
  font-family: Montserrat, Georgia, "Times New Roman", Times, serif;
  font-size: 24.36px;
  line-height: 29.232px;
}

.healthConclusion-p {
  color: #404e67;
  font-family: Montserrat, Georgia, "Times New Roman", Times, serif;
  font-size: 15px;
  line-height: 24px;
}

.healthGraph-h {
  color: #404e67;
  font-family: Segoe UI, Montserrat, Georgia, "Times New Roman", Times, serif;
  font-size: 19px;
  font-weight: 700;
  line-height: 24px;
}

.ccpaLimitations {
  max-height: 750px;
  overflow-y: scroll;
  scrollbar-width: 0px;
}

.ccpaLimitations::-webkit-scrollbar {
  width: 0px;
}

.ccpaList > li {
  list-style: disc;
}

.appendixGraph-h {
  color: #404e67;
  font-family: Segoe UI, Montserrat, Georgia, "Times New Roman", Times, serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 24px;
}

/* CCPA REPORT */

.ccpaNav {
  font-size: 13.5px;
  cursor: pointer;
  background: #e4e4e4;
  margin-right: -15px;
}

.coverHeading {
  height: 65px;
  margin-top: 20px;
  margin-left: 0px;
  font-family: "Effra", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 25px;
  color: #ffffff;
  text-align: left;
  letter-spacing: -0.36px;
  line-height: 36px;
}

.ccpaContent {
  font-size: 12px;
  padding: 5px 50px;
}
.ccpaContent-new {
  font-size: 12px;
  padding: 5px 15px;
  margin-left: 2rem;
}

.ccpaContentHeading {
  color: #2fa89f;
}

.ccpaAppendixList {
  cursor: pointer;
}

/*HealthCheckReport */

.healthCheckContent {
  font-size: 12px;
  padding: 5px 15px;
  margin-left: 2rem;
}

.healthCheckAppendixList {
  cursor: pointer;
}

.reportGraph {
  position: relative;
  width: 670px;
  height: 450px;
  padding: 50px;
  margin: 0px auto 0;
}

.conclusion-tableweb {
  margin-bottom: 3rem;
  font-size: 13px;
}

.SuperuserScroller {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 575px;
  padding: 5px;
}
.calendar-tooltip {
  width: 150px !important;
  background-color: #1ea3cf !important;
}
.actionrequired-button {
  background-color: "red" !important;
  color: "white";
  font-size: "0.8rem";
  margin-left: "3.5rem";
  font-family: "'Raleway', sans-serif";
}
/* multiple email */

.wrapper {
  background-color: white;
  width: 400px;
  padding: 2rem;
  box-shadow: 0 1.5rem 1rem -1rem rgba(0, 0, 0, 0.1);
  border-radius: 0.3rem;
}

.full-screen__loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  height: 100%;
  outline: 0;
  background-color: rgba(79, 79, 79, 0.2);
  display: flex;

  justify-content: center;
  align-items: center;
}

.input {
  width: 65%;
  height: 45px;
  padding: 0 1rem;
  display: block;
  margin-right: auto;
  margin-top: 1rem;
  /* box-sizing: border-box; */
  font-size: 15px;
  border-radius: 0.2rem;
  /* border: 2px solid #d4d5d6; */
  color: #565656;
  -webkit-appearance: none;
}

.input:focus {
  /* border-color: cornflowerblue; */
  outline: none;
}

.input.has-error {
  border-color: tomato;
}

.error {
  margin: 0;
  font-size: 90%;
  color: tomato;
}

.tag-item {
  background-color: #d4d5d6;
  display: block;
  margin-right: auto;
  font-size: 14px;
  border-radius: 30px;
  height: 30px;
  padding: 0 4px 0 1rem;
  display: inline-flex;

  align-items: center;
  margin: 0 0.3rem 0.3rem 0;
}

.tag-item > .button {
  background-color: white;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 10px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Email-send {
  /* color:green; */
  display: inline-flex;
  /* height: 40px !important; */
  padding-top: 20px;

  /* margin-right: auto;  */
  /* align-items: flex-start; */
  /* margin-left: 0%; */
}
.saveBtn-multipleEmail {
  width: 60px !important;
  height: 40px !important;
  /* margin-right: 30px; */
  /* padding-left: 25px;
    padding-right: 25px; */
  background-color: #2fa89f;
  color: #ffffff;
}
.tooltip-inner {
  background-color: #1ea3cf !important;
  text-align: start;

  /* width: 100px; */
}
@media only screen and (min-width: 1390px) {
  .sidenav {
    max-width: 13.66%;
  }
}
@media only screen and (max-width: 1380px) {
  .sidenav {
    max-width: 13.66%;
  }
}
/* 13 inch */

@media only screen and (max-width: 1080px) {
  .sidenav {
    max-width: 16.66%;
  }
}

.phone-input {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
}
