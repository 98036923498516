.dyervision-p {
    font-size: 14px;
}

.scmd-tr {
    font-size: 14px;
}

.scmd-progresswidget {
    margin-left: 2rem;
}

.bottomicon {
    max-height: 23% !important;
    background-color: #a2b9be;
}

.menuListItem {
    margin-bottom: 5px !important;
}

.speechBox {
    width: 300px;
    margin: 50px auto;
    background: #00bfb6;
    padding: 20px;
    text-align: center;
    font-weight: 900;
    color: #fff;
    font-family: arial;
    position: relative;
}
/* gap-analysis */
.scmgap-report {
    margin-left: -8rem;
}
.scmga-preportleft {
    margin-left: 20px;
    margin-top: 25px;

    height: 650px;
    /* overflow-y: scroll; */

    border: 1px solid #e7e7e7;
    padding: 15px;
}
.scmgap-reportleft > p {
    font-size: 13px;
}
.scmgap-reportborder {
    width: 950px !important;
    border: 2px solid #e7e7e7;
    border-radius: 5px;
    padding: 30px;
    margin-top: 24px;

    height: 650px;
    overflow-y: scroll;
}

/* User Progress */
.userHeading {
    font-family: 'Effra', Helvetica, Arial, serif;
    font-weight: 400;
    font-style: normal;
    color: white;
    font-size: 20px;
    letter-spacing: -0.36px;
}

/* slider */

.sliderHeading {
    font-family: 'Effra', Helvetica, Arial, serif;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    letter-spacing: -0.36px;
}

.sliderRangeHeading {
    margin-bottom: 10px;
}
.rc-slider {
    width: 555px !important;
}

.sliderMain {
    margin-left: 80px;
    margin-top: 30px;
}

.rc-slider-rail {
    background: linear-gradient(to right, #ec6f66, #fda737, #2fa89f) !important;
    height: 10px !important;
}

.rc-slider-track {
    background-color: transparent !important;
}

.rc-slider-handle {
    width: 30px !important;
    height: 30px !important;
    margin-top: -10px !important;
    border: solid 7px #6e6f70 !important;
    background-color: transparent !important;
    transform: translateX(0px) !important;
}

.rc-slider-disabled {
    background-color: transparent !important;
}

.non {
    font-size: 14px;
}

.partly {
    font-size: 14px;
    margin-left: 60px;
}

.compliant {
    font-size: 14px;
    margin-left: 100px;
}

.circularWidgetColumn {
    max-height: 710px;
    /* overflow-y: scroll; */
    overflow-x: hidden;
    padding: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    /* background-color: #e7e7e7; */
}

.verticalWidgetColumn {
    height: 710px;
    margin-bottom: 10px;
    padding: 0px;
    margin-inline: 5px;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #e7e7e7;
}

/* .circularWidgetColumn::-webkit-scrollbar {
    display: none;
} */

/* 1920 */

@media only screen and (max-width: 1930px) {
    .scm-pw {
        width: 35% !important;
    }

    .scm-dv {
        width: 38% !important;
    }

    .scmd-progresswidget {
        width: 130% !important;
        margin: -1.3rem 0 0 -0.5rem;
    }

    .bottomicon {
        max-height: 18% !important;
        background-color: #a2b9be;
    }

    .scm-cpw {
        font-size: 70px;
    }

    .fsp {
        font-size: 50px;
        margin-top: -15px;
    }

    .dashboard-cpw {
        font-size: 60px;
    }

    .dashboard-percentage {
        font-size: 40px;
    }

    .progressWidgetNoCircle {
        width: 100%;
        overflow: auto;
        /* margin-right: 0rem; */
        height: 100%;
        /* height:100% !important; */
    }

    .progressWidget {
        /* width: 300px !important; */
        margin-left: 0rem;
    }
}

/* 1680 */
@media only screen and (max-width: 1690px) {
    .scm-pw {
        width: 35% !important;
    }

    .scm-dv {
        width: 38% !important;
    }

    .scmd-progresswidget {
        width: 130% !important;
        margin: -1.3rem 0 0 -0.5rem;
    }

    .bottomicon {
        max-height: 20% !important;
        background-color: #a2b9be;
    }

    .progressWidgetNoCircle {
        width: 100%;
        /* margin-right: 0rem; */
        height: 100%;
        /* height:100% !important; */
    }

    .dashboard-cpw {
        font-size: 50px;
    }

    .dashboard-percentage {
        font-size: 30px;
    }

    .scm-vgp {
        margin: -0.5rem 0 0 2rem;
    }
}

/* 15.5 */
@media only screen and (max-width: 1580px) {
    .progressWidgetNoCircle {
        width: 100%;
        /* margin-right: 0rem; */
        height: 100%;
        /* height:100% !important; */
    }

    .scm-vgp {
        margin: -0.5rem 0 0 2rem;
    }

    .dashboard-cpw {
        font-size: 45px;
    }

    .dashboard-percentage {
        font-size: 25px;
        margin-top: -20px;
    }
}

/* 15inch */
@media only screen and (max-width: 1440px) {
    .progressWidgetNoCircle {
        width: 100%;
        /* margin-right: 0rem; */
        height: 100%;
        /* height:100% !important; */
    }

    .scm-pw {
        width: 35% !important;
    }

    .scm-dv {
        width: 38% !important;
    }

    .scmd-progresswidget {
        width: 150% !important;
        margin: -1.3rem 0 0 -2.5rem;
    }

    .scm-vgp {
        margin: -0.5rem 0 0 2rem;
    }

    .dashboard-cpw {
        font-size: 45px;
    }

    .dashboard-percentage {
        font-size: 25px;
        margin-top: -20px;
    }
}

/* 14 inch */
@media only screen and (max-width: 1370px) {
    .daisyIconPanel-h4 {
        font-size: 14px !important;
    }

    .progressWidgetNoCircle {
        width: 100%;
        /* margin-right: 0rem; */
        height: 100%;
        /* height:100% !important; */
    }

    .scm-pw {
        width: 35% !important;
    }

    .scm-dv {
        width: 38% !important;
    }

    .scmd-progresswidget {
        width: 150% !important;
        margin: -1.3rem 0 0 -2.5rem;
    }

    .scm-cpw {
        font-size: 50px;
    }

    .fsp {
        font-size: 30px;
        margin-top: -15px;
    }

    .dashboard-cpw {
        font-size: 30px;
    }

    .dashboard-percentage {
        font-size: 20px;
    }
}

/* 13 inch */

@media only screen and (max-width: 1280px) {
    .daisyIconPanel-h4 {
        font-size: 12px !important;
    }

    .scm-pw {
        width: 35% !important;
    }

    .scm-dv {
        width: 35% !important;
    }

    /* progress widget in vendors */
    .scmd-progresswidget {
        width: 150% !important;
        margin: -1.3rem 0 0 -2.5rem;
    }
    .scm-cpw {
        font-size: 30px !important;
    }
    .fsp {
        font-size: 20px !important;
        margin-top: -15px;
    }
}

@media only screen and (max-width: 1220px) {
    .Main-Card-Col {
        display: flex;
        flex-direction: row;
        overflow-y: scroll;
        overflow-x: hidden;
        height: 800px;
    }

    .vendor-container {
        overflow-y: scroll;
        /* background-color: red; */
        overflow-x: hidden;
        height: 95%;
        width: 105%;
    }

    .progressWidgetNoCircle {
        /* position: absolute; */
        top: 40rem;
        left: -42rem;
        height: 100%;
    }

    .scm-main-row {
        display: flex;
        flex-direction: column;
    }
    .scm-dv {
        display: flex;

        flex-direction: column;
    }
    .scm-pw {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
    .scm-vgp {
        position: absolute;
    }
}

/* tabsize */
@media (min-width: 990px) and (max-width: 1051px) {
    .vendor-container {
        overflow-y: scroll;
        /* background-color: #00bfb6; */
        overflow-x: hidden;
        height: 95%;
        width: 105%;
    }
    .Main-Card-Col {
        display: flex;
        flex-direction: row;
        overflow-y: scroll;
        overflow-x: hidden;
        height: 800px;
    }

    .progressWidgetNoCircle {
        position: absolute;
        top: 40rem;
        left: -42rem;
        height: 100%;
    }
    .scm-main-row {
        display: flex;
        flex-direction: column;
    }
    .scm-dv {
        display: flex;

        flex-direction: column;
    }

    .scm-pw {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
    .scm-vgp {
        position: absolute;
        /* margin-left: 50rem;
            margin-top: -38rem;
             */
    }
}

@media (min-width: 768px) and (max-width: 819px) {
    .vendor-container {
        overflow-y: scroll;
        /* background-color: #00bfb6; */
        overflow-x: hidden;
        height: 95%;
        width: 105%;
    }

    .squareWidgetCard {
        position: relative;
        /* width: 245px; */
        /* margin-left: -3rem; */
        /* top: 0.5rem;
        margin-bottom: 3.5rem; */
    }

    .progressWidgetNoCircle {
        position: absolute;
        top: 3rem;
        left: -2rem;
        height: 100%;
    }
}

@media (min-width: 820px) and (max-width: 989px) {
    .vendor-container {
        overflow-y: scroll;
        /* background-color: #00bfb6; */
        overflow-x: hidden;
        height: 95%;
        width: 105%;
    }

    .progressWidgetNoCircle {
        position: absolute;
        top: 3rem;
        left: -1rem;
        height: 100%;
    }
}

@media (min-width: 820px) and (max-width: 789px) {
}
