.cover {
    height: 770px;
    width: 600px !important;
}

.coverContainer {
    position: relative;
    color: white;
}

.cover-h {
    position: absolute;
    font-size: 50px;
    top: 30%;
    left: 30%;
    transform: translate(-50%, -50%)
}

.ccpa-cover-h {
    position: absolute;
    font-size: 40px;
    top: 30%;
    left: 40%;
    transform: translate(-50%, -50%)
}

.cover-p {
    position: absolute;
    font-size: 16px;
    top: 45%;
    left: 30%;
    transform: translate(-50%, -50%);
}

.cover-c {
    position: absolute;
    font-size: 16px;
    top: 50%;
    left: 30%;
    transform: translate(-50%, -50%);
}

.cover-d {
    position: absolute;
    font-size: 16px;
    top: 55%;
    left: 30%;
    transform: translate(-50%, -50%);
}

.column {
    margin-top: 5rem;
    margin-bottom: 19rem;
}

.ccpa-column {
    margin-top: 14rem;
    margin-bottom: 21rem;
}

.health-column {
    margin-top: 14rem;
    margin-bottom: 21rem;
}

.h1-column {
    display: block;
    float: left;
    padding-top: 2rem;
    margin-top: 8rem;
    color: rgba(47, 168, 159, 1);
    transform: rotate(-90deg);
    font-weight: 600;
    font-family: Roboto;
    font-size: 50px;
}

.ccpa-h1-column {
    display: block;
    float: left;
    padding-top: 1rem;
    margin-top: 7rem;
    color: rgba(47, 168, 159, 1);
    transform: rotate(-90deg);
    font-weight: 600;
    font-family: Roboto;
    font-size: 40px;
}

.table-column {
    font-size: 10px;
    display: inline-block;
    margin-left: -3rem;
}

.ccpa-table-column {
    font-size: 10px;
    display: inline-block;
    margin-top: 5rem;
    margin-left: -2rem;
}

.td-main {
    width: 290px;
}

.ccpatd-main {

    width: 290px;
}

.td-sub {
    width: 283px;
}

.td-rec {
    padding-left: 17px;
}

.welcomeText-report {
    /* height: 65px; */
    margin-top: 20px;
    margin-left: 0px;
    font-family: 'Effra', Helvetica, Arial, serif;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    color: rgba(47, 168, 159, 1);
    text-align: left;
    letter-spacing: -0.36px;
    line-height: 36px;
}

.intro {
    width: 600px;
    margin-left: 1rem;
    
    margin-right: 4rem;
    padding: 1rem 4rem;
    display: block;
}

.intro-slider {
    width: 600px;
    margin-left: 1rem;
  
    margin-right: 4rem;
    padding: 1rem 4rem;
    display: block;
}

.services-intro {
    width: 600px;
    margin-left: 1rem;
    
    margin-right: 4rem;
    padding: 1rem 4rem 1rem 2rem;
    display: block;
}



.ccpa-intro {
    width: 600px;
    margin-left: 1rem;
    
    margin-right: 4rem;
    padding: 1rem 4rem;
    display: block;
}

.intro-h1 {
    font-family: 'Effra', Helvetica, Arial, serif;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    color: rgba(47, 168, 159, 1);
    letter-spacing: -0.36px;
}

.intro-p {
    font-family: 'Effra', Helvetica, Arial, serif;
    text-align: justify;
    text-justify: inter-word;
    color: #6e6f70;
    padding: 0rem 6rem 0rem 0rem;
    margin-right: 3rem;
    line-height: 15px;
}

.intro-li {
    margin-right: 3rem;
    padding-right: 5rem;
    text-align: justify;
    line-height: 15px;
    list-style-type: disc;
}

.intro-li-Web {
    text-align: justify;
    line-height: 20px;
    list-style-type: disc;
}

.gdpr-li {
    margin-bottom: 2rem;
}

.bgColor {
    background-color: #ffffff;
}

@media print {
    body {
        background-color: #ffffff;
        width: 21cm;
        height: 29.7cm;
        margin: 30mm 35mm 30mm 35mm;
        /* change the margins as you want them to be. */
    }
}

.reportData {
    font-size: 12px;
}

.reportData {
    font-size: 12px;
}

.welcomeText-report {
    height: 65px;
    margin-top: 20px;
    margin-left: 0px;
    font-family: 'Effra', Helvetica, Arial, serif;
    font-weight: 400;
    font-style: normal;
    font-size: 36px;
    color: rgba(47, 168, 159, 1);
    text-align: left;
    letter-spacing: -0.36px;
    line-height: 36px;
}

.reportSubHeading {
    font-family: 'Effra', Helvetica, Arial, serif;
    font-weight: 400;
    font-style: normal;
    font-size: 28px;
    color: rgba(47, 168, 159, 1);
}

.roadMapData {
    font-size: 14px;
}

.tr-list {
    text-align: justify;
    text-justify: inter-word;
    width: 400px;
    font-size: 10px;
    font-weight: 200;
    font-family: 'Effra';
    color: #6e6f70;
    line-height: 15px;
    margin-right: 2rem;
    padding-right: 5rem;
    padding-left: 0px;
}

.warning {
    text-align: center;
    line-height: normal;
}

.pdfReport {
    font-size: 8px;
    padding: 5px 15px;
}

.reportAddress {
    margin-top: 1rem;
    margin-left: 7rem;
    font-size: 10px;
    font-family: 'Effra', Helvetica, Arial, serif;
    font-weight: 200;
    color: #0090df;
}

.ccpa-reportAddress {
    margin-top: 1rem;
    margin-left: 7rem;
    font-size: 10px;
    font-family: 'Effra', Helvetica, Arial, serif;
    font-weight: 200;
    color: #0090df;
}

.sliderHeading {
    font-family: 'Effra', Helvetica, Arial, serif;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    letter-spacing: -0.36px;
}

.sliderRangeHeading {
    margin-bottom: 14px;
}

.rc-slider {
    width: 400px !important;
}

.sliderMain {
    margin-top: 13px;
}

.rc-slider-rail {
    background: linear-gradient(to right, #EC6F66, #fda737, #2fa89f) !important;
    height: 14px !important;
}

.rc-slider-track {
    background-color: transparent !important;
}

.rc-slider-handle {
    width: 30px !important;
    height: 30px !important;
    margin-top: -7px !important;
    border: solid 7px #6e6f70 !important;
    background-color: transparent !important;
    transform: translateX(0px) !important;
}

.rc-slider-disabled {
    background-color: transparent !important;
}

.non {
    font-size: 10px;
}

.partly {
    font-size: 10px;
    margin-left: 60px;
}

.compliant {
    font-size: 10px;
    margin-left: 100px;
}

.recommendation {
    font-family: 'Effra', Helvetica, Arial, serif;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    color: rgba(47, 168, 159, 1);
}

.recomDiv {
    width: 500px;
    display: block;
}

.recomDivWeb {
    width: 700px;
    display: block;
}

.recomContent {
    text-align: justify;
    text-justify: inter-word;
    font-family: 'Effra-light';
    color: #6e6f70;
    font-size: 12px;

}

.recomContentWeb {
    text-align: justify;
    text-justify: inter-word;
    font-family: 'Effra-light';
    color: #6e6f70;
    font-size: 15px;
}

.ccpa-recomContent {
    font-family: 'Effra';
    color: #6e6f70;
    font-size: 10px;
}

.ccpa-recomContentWeb {
    font-family: 'Effra';
    color: #6e6f70;
    font-size: 15px;
}

.recomContentMain {
    margin-right: 2rem;
    padding-right: 5rem;
}

.ccpa-reportContent {
    font-size: 10px;
    font-family: 'Effra';
    color: #6e6f70;
}

.summary{
    width: 600px;
    margin-left: 1rem;
    margin-top: 1rem;
    margin-right: 4rem;
    padding: 1rem 2rem;
    display: block;
}
.services-recommendation{
    margin-top: 1rem;
}
.services-tr-pdf{
text-align: justify;
text-justify: inter-word;
width: 500px;
font-size: 11px;
font-weight: 200;
font-family: 'Effra-medium';
color:black;
line-height: 15px;


};

.services-tr-dot {
    max-width: 20px !important;
 
}