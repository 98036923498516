@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import url('./assets/homepage.css');
@import url('./assets/login.css');
@import url('./assets/table.css');
@import url('./assets/healthCheckPdf.css');
@import url('./assets/pdf.css');
@import url('./assets/vendor.css');
