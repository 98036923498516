.login-routes__container {
    margin-bottom: 20px;
    max-width: 950px;
}
.login-routes__container .login-routes__left-col {
    background-repeat: no-repeat;
    background-size: cover;
}
.login-routes__container .login-routes__left-col .login-routes__heading {
    font-weight: bold;
    line-height: 1;
}
.login-routes__container .login-routes__left-col .login-routes__sub-heading {
    font-weight: normal;
    line-height: 1;
}
.login-routes__container .login-routes__left-col .login-routes__link__row {
    display: flex;
    align-items: flex-end;
}
.login-routes__container
    .login-routes__left-col
    .login-routes__link__row
    .login-routes__link__message
    a {
    padding-left: 12px;
    text-decoration: underline;
}
.login-routes__container .login-routes__right-col .form-group,
.login-routes__container .login-routes__right-col label {
    margin-bottom: 0px;
}
.login-routes__container .login-routes__right-col .login__heading {
    font-weight: bold;
    line-height: 1;
}
.login-routes__container .login-routes__right-col .login__sub-heading {
    margin-bottom: 8px;
}
.login-routes__container .login-routes__right-col .login__inputs {
    padding-bottom: 8px;
}
.login-routes__container .login-routes__right-col .login__button__row {
    margin-top: 12px;
}
.login-routes__container .login-routes__right-col .login__button__row .col {
    display: flex;
    align-items: flex-end;
}
.login-routes__container .login-routes__right-col .login__button__row a {
    padding-left: 12px;
    text-decoration: underline;
}
.login-routes__container .login-routes__right-col .required {
    font-size: 0.5rem;
    vertical-align: middle;
}
.login-routes__container .login-routes__right-col .form-check-input {
    height: 15px;
    width: 15px;
}
.login-routes__container .login-routes__right-col .surchx-help {
    font-weight: bold;
}
.login-routes__container .login-routes__right-col .link {
    text-decoration: underline;
    cursor: pointer;
}

#bg {
    position: fixed;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
}

#bg img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    min-width: 50%;
    min-height: 50%;
}

#page-wrap {
    position: relative;
    z-index: 2;
    width: 400px;
    margin: 50px auto;
    padding: 20px;
}

.username {
    position: relative;
}

.username:before {
    content: '';
    position: absolute;
    left: 10px;
    top: 0;
    bottom: 0;
    width: 20px;
    background: url('./images/Icon_Login_Username.svg') center / contain
        no-repeat;
}

.rectangle231402 input {
    padding: 10px 30px;
    background-color: #e4e4e4 !important;
    color: #2a2c30;
    border-width: 0px !important;
    border: none !important;
}

input:focus {
    outline: none !important;
}

.form-control:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.input-group-prepend {
    margin-right: 0px;
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e4e4e4;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.form-login {
    margin-top: 80px;
}

.form-username {
    margin-bottom: 15px;
}

.form-password {
    margin-bottom: 15px;
}

.form-button {
    background-color: rgba(47, 168, 159, 1);
    top: 0px;
    height: 52px;
    width: 351px;
    position: absolute;
    margin: 0;
    left: 0px;
    border-radius: 6px;
    transform: rotate(0deg);
    opacity: 1;
    color: #ffffff;
}

.form-button:hover {
    background-color: rgba(47, 168, 159, 1);
}

.forgot-pass-link {
    margin-top: 65px;
}

.forgot-pass {
    background-color: rgba(255, 255, 255, 0);
    width: 340px;
    position: absolute;
    margin: 0;
    transform: rotate(0deg);
    opacity: 1;
    font-family: 'Effra', Helvetica, Arial, serif;
    font-weight: 200;
    font-style: normal;
    font-size: 18px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    letter-spacing: -0.18px;
    line-height: 21px;
    text-decoration: none;
}

.forgot-pass:hover {
    color: rgba(255, 255, 255, 1);
    text-decoration: none;
}

.password-field {
    margin-top: 10px;
    background-color: #e4e4e4 !important;
}

.password-formPage {
    margin-top: 80px;
    margin-bottom: 10px;
}

.passwordHeadingText {
    color: #ffffff;
    font-size: 50px;
}

.passwordSubText {
    margin-top: 10px;
    color: #ffffff;
    font-size: 45px;
    font-weight: 300;
}

.passSubmitButton {
    margin-top: 20px;
    background-color: #2fa89f;
}

.passButtonAlign {
    text-align: center;
}

.passwordRemText {
    margin-top: 20px;
    text-align: center;
    color: #ffffff;
    font-size: 45px;
}

.passwordRemText a {
    color: #0090df !important;
}

.passwordRemText a:hover {
    color: #ffffff !important;
    text-decoration: none;
}
.signup-checkbox {
    margin-left: 1rem;
    font-weight: 400 !important;
}
